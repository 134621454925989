import React from "react";
import ZohoRecruitForm from './ZohoRecruitForm';
function Career() {
  return (
    <>
      
      <ZohoRecruitForm />
    </>
  );
}

export default Career;
