import React, { useEffect } from 'react';

const ZohoRecruitForm = () => {
  useEffect(() => {
    const f = document.createElement('iframe');
    f.src = 'https://qtohouse.zohorecruit.com/forms/15e491448950bb523128fb50de28c0347882537048efb9504570beda25a4441e';
    f.style.border = 'none';
    f.style.height = '700px';
    f.style.width = '100%';
    f.style.transition = 'all 0.5s ease';
    
    const d = document.getElementById('zr_div_564977000000415005');
    d.appendChild(f);

    const handleMessage = (event) => {
      // Check if event.data is a string and contains the expected delimiter
      if (typeof event.data === 'string' && event.data.includes('|')) {
        const zr_ifrm_data = event.data.split('|');
        const zr_perma = zr_ifrm_data[0];
        const zr_ifrm_ht_nw = `${parseInt(zr_ifrm_data[1], 10) + 15}px`;
        const iframe = d.getElementsByTagName('iframe')[0];
        
        if (iframe.src.includes('formperma') && iframe.src.includes(zr_perma)) {
          const prevIframeHeight = iframe.style.height;
          if (prevIframeHeight !== zr_ifrm_ht_nw) {
            iframe.style.height = zr_ifrm_ht_nw;
          }
        }
      } else {
        console.error('Received unexpected message data:', event.data);
      }
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return <div id="zr_div_564977000000415005"></div>;
};

export default ZohoRecruitForm;
