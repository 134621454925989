import React from 'react';

function BlogDetail7 () {
  return (
    <>
      <div className="blog-single gray-bg"></div>

      <div className="blog-single gray-bg">

      </div>

    </>
  );
}

export default BlogDetail7 ;
