import React, { useState, useEffect } from "react";
import axios from "axios";

const Login = () => {
  const [odooData, setOdooData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const odooApiUrl = "http://192.168.31.250:8069/";
        const database = "db-1";
        const username = "fayzsarfraz@gmail.com";
        const password = "Abc@123.com";

        const authResponse = await axios.post(
          `${odooApiUrl}/web/session/authenticate`,
          {
            jsonrpc: "2.0",
            method: "call",
            params: {
              db: database,
              login: username,
              password: password,
            },
          }
        );

        const sessionId = authResponse.data.result.session_id;

        const response = await axios.post(`${odooApiUrl}/web/dataset/call_kw`, {
          jsonrpc: "2.0",
          method: "call",
          params: {
            model: "your-odoo-model", // Replace with the actual model name
            method: "search_read",
            args: [],
            kwargs: {
              context: {
                session_id: sessionId,
              },
            },
          },
        });

        setOdooData(response.data.result.records);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Odoo data:", error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {odooData.map((item) => (
              <li key={item.id}>{item.name}</li>
              // Adjust 'name' based on the actual field you want to display
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Login;
